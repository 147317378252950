import React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import useIsInViewport from "../hooks/useIsInViewport";
import { isEven } from "../helpers/helpers";
import joalis from "../img/hp/joalis.png";
import boxSmall from "../img/hp/krabice-mala.jpg";
import mpm from "../img/hp/mpm.png";
import code8 from "../img/hp/nav1.jpg";
import ourWork from "../img/hp/nav2.jpg";
import testCoverage from "../img/hp/test-coverage.png";
import nanits from "../img/hp/nanits.jpg";
import prague from "../img/hp/nav4.jpg";

const news = [
    {
        imgSrc: joalis,
        alt: "Joalis",
        ribbon: "Launched!",
        link: "https://www.joalis.cz",
        linkText: "Joalis website",
        text: "Joalis produces unique natural food supplements which combine the effects of herbs and plants with the power of information. We've been commissioned to deliver this awesome project from A-Z and we hope you like the results.",
        ctaText: "Have a look",
    },
    {
        imgSrc: boxSmall,
        alt: "Box small",
        ribbon: "News",
        link: "https://www.shop8.cz",
        linkText: "When off-the-shelf software feels too restrictive",
        text: "We've built a modular digital commerce solution to save cost, speed up go-to-market and offer our clients tailored high performing shopping experience. We've gathered a lot of skills over the years - this is where it all comes together. Powerfull backoffice on one end, performant front-shop / mobile app on the other.",
        ctaText: "Our e-commerce solution",
    },
    {
        imgSrc: mpm,
        alt: "Mobile Phone Museum",
        ribbon: "Launched!",
        link: "https://www.mobilephonemuseum.com/catalogue/",
        linkText: "Museum of Mobile Phones",
        text: 'In collaboration with British and Czech collectors and enthusiasts an amazing project of the largest collection of mobile phones in the world was created. A neat design job provided by folks from <a href="https://www.toman-design.com">Toman Design</a> brought to life with CMS and static site generator.',
        ctaText: "Check this out!",
    },
];
const intro = [
    {
        imgSrc: ourWork,
        alt: "Our's work",
        link: "/about/",
        linkText: "We’re a dedicated team of experts",
        text: "Having previously worked both client-side and in agency environments in the UK we understand how different teams like to operate and how this has evolved over the years.",
        ctaText: "More about us",
    },
    {
        imgSrc: code8,
        alt: "Code8",
        link: "/approach/",
        linkText: "A remote team that is anything but",
        text: "Remote teams are commonplace today, but not many offer the feeling of having your team in house. We ensure we keep communication high so it’s as if we are right there with you.",
        ctaText: "Our approach",
    },
    {
        imgSrc: testCoverage,
        alt: "Test coverage",
        link: "/approach/",
        linkText: "We enjoy a good night sleep",
        text: "That's why we test our code from early on. It brings about peace of mind. It gives us confidence. And it helps our clients scale their businesses smoothly.",
        ctaText: "See how we approach testing",
    },
    {
        imgSrc: nanits,
        alt: "Nanits",
        link: "/services/",
        linkText: "We don’t profess to do everything",
        text: "But we’ve become specialists in many areas that we think are valuable to a wide range of businesses, and our skill sets are constantly growing.",
        ctaText: "Our Services",
    },
    {
        imgSrc: prague,
        alt: "Prague",
        link: "/contact/",
        linkText: "We’d like to hear from you",
        text: "We’re based in Prague, but want to feel like we’re just next door.",
        ctaText: "Get in touch",
    },
];

const Index = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="Code 8 presents..." />
            <h2 className="line animate-1">What has been happening?</h2>
            <div className="news">
                {news.map((item, index) => (
                    <article className="article" ref={setRef} data-observer={index} key={item.alt}>
                        {isEven(index) && (
                            <div className="left animate-2">
                                <img src={item.imgSrc} alt={item.alt} />
                            </div>
                        )}
                        <div className={isEven(index) ? "right" : "left"}>
                            <h4 className={`ribbon${isEven(index) ? " animate-3" : " animate-2"}`}>{item.ribbon}</h4>
                            <h3 className={`${isEven(index) ? "animate-4" : "animate-3"}`}>
                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                    {item.linkText}
                                </a>
                            </h3>
                            <p className={`${isEven(index) ? "animate-5" : "animate-4"}`} dangerouslySetInnerHTML={{ __html: item.text }}></p>
                            <p className={`${isEven(index) ? "animate-6" : "animate-5"}`}>
                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                    {item.ctaText}
                                </a>
                            </p>
                        </div>
                        {!isEven(index) && (
                            <div className="right animate-6">
                                <img src={item.imgSrc} alt={item.alt} />
                            </div>
                        )}
                    </article>
                ))}
            </div>
            <h2 className="line animate-1" ref={setRef} data-observer={4}>
                Who are we?
            </h2>
            <div className="intro">
                {intro.map((item, index) => (
                    <article className="article" ref={setRef} data-observer={index + 5} key={item.alt}>
                        {!isEven(index) && (
                            <div className="left animate-2">
                                <img src={item.imgSrc} alt="Code8" />
                            </div>
                        )}
                        <div className={`${isEven(index) ? "left animate-3" : "right animate-2"}`}>
                            <h3 className={`${isEven(index) ? "animate-4" : "animate-3"}`}>
                                <Link to={item.link}>{item.linkText}</Link>
                            </h3>
                            <p className={`${isEven(index) ? "animate-5" : "animate-4"}`}>{item.text}</p>
                            <p className={`${isEven(index) ? "animate-6" : "animate-5"}`}>
                                <Link to={item.link}>{item.ctaText}</Link>
                            </p>
                        </div>
                        {isEven(index) && (
                            <div className="right animate-6">
                                <img src={item.imgSrc} alt="Code8" />
                            </div>
                        )}
                    </article>
                ))}
            </div>
        </>
    );
};
export default Index;
